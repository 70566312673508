import { takeEvery, put } from "redux-saga/effects";
import { request, LoginRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/hashrouter";

export default function* watcherResetPasswordSaga() {
    yield takeEvery("RESET_PASSWORD", workerSaga);
}

function* workerSaga(action) {
    try {
        yield put({ type: "LOADING_BUTTON_SPINNERS", payload: true });
        let payload = {};
        yield put({ type: "USER_PASSWORD_EMAIL", payload: action.payload.email });
        yield LoginRequest("post", action.payload, "api/v1/auth/resetpassword").then(
            (response) => {
                payload = response;
            }
        );
        yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
      history.push("/input-code")
    } catch (e) {
        console.log(e?.response?.status);
        if (e?.response?.response == "NOT_FOUND") {
            toast.error("Invalid Credentials");
            yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
        } else {
            console.log("login-saga", e);
            console.log("login-saga", e.response);
            yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
            const errorMessage = getErrorMessage(e.response);
            toast.error(errorMessage);
            yield put({ type: "API_ERRORED", payload: e });
        }
    }
}
