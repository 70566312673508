import { takeEvery, put } from "redux-saga/effects";
import { request, LoginRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/hashrouter";

export default function* watcherUpdatePasswordSaga() {
    yield takeEvery("INPUT_CODE", workerSaga);
}

function* workerSaga(action) {
    try {
        yield put({ type: "LOADING_BUTTON_SPINNERS", payload: true });
        let payload = {};
        yield LoginRequest("post", action.payload, "api/v1/auth/updatepassword").then(
            (response) => {
                payload = response;
            }
        );
        yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
        toast.success("Welcome Back!!!");
        history.push("/");
    } catch (e) {
        toast.error("Invalid Credentials")
        yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
    }
}
