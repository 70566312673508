import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherDeleteUserSaga() {
  yield takeEvery("DELETE_USER", workerSaga);
}

function* workerSaga(action) {
  try {
   
    yield put({ type: "DISPLAY_LOADER" ,payload:true }); 
    let payload = {};
    yield request(
      "put",
      action.payload,
      `api/deactivate-user/${action.payload}`
    ).then((response) => {
      payload = response;
    });
    toast.success("Deleted User Successfully!");
    var formatUrl = `api/users/1`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "USER_LISTS", payload: payload.items });
    yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
    yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
    yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });
    var formatUrl = `api/userType`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "USER_TYPE_LIST", payload: payload });
    var formatUrl = `api/position`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "POSITION_LIST", payload: payload });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("delete-user-saga", e);
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
    console.log("delete-user-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
