import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watchgerGetJobTask() {
  yield takeEvery("LOAD_JOB_TASK", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_BAR_LOADER", payload: true });
    let payload = {};
    var url = `api/v1/task/available?jobId=${action.payload.jobId}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    var taskList = [];
    if (payload.response.taskList != null) {
      var i = 0;
      for (i; i < payload.response.taskList.length; i++) {
        if (!payload.response.taskList[i].isAssigned) {
          taskList.push({
            label: payload.response.taskList[i].taskName,
            value: payload.response.taskList[i].id,
          });
        }
      }
    }
    yield put({
      type: "JOB_TASK_DROPDOWN",
      payload: taskList,
    });
    var formatUrl = `api/users`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "USERS_MULTI_DROPDOWN", payload: payload });
    yield put({ type: "DISPLAY_BAR_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("user-list-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
