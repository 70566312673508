import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store"
export default function* watcherGetReportDetailSaga() {
  yield takeEvery("LOAD_REPORT_DETAIL", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true })
    let payload = {};
    var url = `api/v1/report/detail?endDate=${action.payload.endDate}T00:00&reportId=${action.payload.type}&startDate=${action.payload.startDate}T00:00&userId=${action.payload.userId}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "REPORT_DETAIL_LIST", payload: payload.response });
    var url = `/api/v1/dropdown/user`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });

    yield put({ type: "PERSIST_USERS", payload: payload });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("user-list-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
