import watcherLoginSaga from "../saga/onboarding/login-saga";
import watcherGetClients from "./clients/client-list-saga";
import watcherAddClientSaga from "./clients/add-client-saga";
import watcherUpdateClientSaga from "./clients/update-client-saga";
import watcherDeleteClientSaga from "./clients/delete-client-saga";
import watcherGetUsers from "./user/user-list-saga";
import watcherAddUserSaga from "./user/add-user-saga";
import watcherUpdateUserSaga from "./user/update-user-saga";
import watcherDeleteUserSaga from "./user/delete-user-saga";
import watcherGetJobs from "./job/job-list-saga";
import watcherAddJobSaga from "./job/add-job-saga";
import watcherGetCardJobs from './job/card-job-saga';
import watcherUpdateJobSaga from "./job/update-job-saga";
import watcherDeleteJobSaga from "./job/delete-job-saga";
import watcherAddTaskSaga from "./job/add-task-saga";
import watcherGetJobHistory from "./job/job-history-saga";
import watcherGetPendingTasksSaga from "./job/pending-tasks-saga";
import watcherGetDashboardSaga from "./dashboard/dashboard-saga";
import watcherDeleteTaskSaga from "./job/delete-task-saga";
import watcherReworkSaga from "./job/rework-saga";
import watcherValidateSaga from "./job/validate-saga";
import watcherGetUser from "./user/user-saga";
import watchherGetUserTask from "./job/user-task-saga";
import watcherUpdateUserAdminSaga from "./user/update-user-admin";
import watcherGetReportSaga from "./report/report-list-saga";
import watcherGetJobType from "./job/job-type-saga";
import watcherGetJobTypeJob from "./job/job-type-job-saga";
import watchgerGetJobTask from "./job/job_task_saga";
import watcherUpdateTaskSaga from "./job/update-task-saga";
import watcherResetPasswordSaga from "./onboarding/reset-password-saga";
import watcherUpdatePasswordSaga from "./onboarding/input-code-saga";
import watchherGetUserComment from "./job/user-comment-saga";
import watcherGetReportDetailSaga from "../saga/report/report-details-list";
import watcherGetReportDetailForExcelSaga from "./report/report-detail-excel";
import watcherGetDashboardCsvSaga from "./dashboard/dashboard-csv";
import watcherReAssignTaskSaga from "./job/re-assign-task";
import watcherGetReportJobs from "./job/job-report-saga";
import watcherAddTaskFileSaga from "./job/add-task-file"
import watcherDeleteTaskFileSaga from "./job/delete-task-file";
import watcherGetTaskFile from "./job/get-taskfile-saga";
import watcherGetUsersDropdown from './user/users-dropdown';
import watcherAddNotification from './job/add-notification'
export const UiSagas = [
  watcherReworkSaga(),
  watcherGetReportDetailSaga(),
  watcherGetTaskFile(),
  watcherDeleteTaskFileSaga(),
  watcherReAssignTaskSaga(),
  watcherValidateSaga(),
  watcherGetReportDetailForExcelSaga(),
  watcherAddTaskFileSaga(),
  watcherGetDashboardCsvSaga(),
  watcherLoginSaga(),
  watcherGetUser(),
  watchherGetUserComment(),
  watcherGetUsersDropdown(),
  watcherDeleteTaskSaga(),
  watcherGetDashboardSaga(),
  watcherResetPasswordSaga(),
  watcherUpdatePasswordSaga(),
  watcherGetClients(),
  watcherAddClientSaga(),
  watcherUpdateClientSaga(),
  watcherDeleteClientSaga(),
  watcherGetUsers(),
  watcherGetJobs(),
  watcherAddJobSaga(),
  watcherUpdateJobSaga(),
  watcherAddUserSaga(),
  watcherUpdateUserSaga(),
  watcherDeleteUserSaga(),
  watcherDeleteJobSaga(),
  watcherAddTaskSaga(),
  watcherGetJobHistory(),
  watcherGetPendingTasksSaga(),
  watcherGetReportJobs(),
  watchherGetUserTask(),
  watcherUpdateUserAdminSaga(),
  watcherGetCardJobs(),
  watcherGetReportSaga(),
  watcherGetJobType(),
  watcherGetJobTypeJob(),
  watchgerGetJobTask(),
  watcherUpdateTaskSaga(),
  watcherAddNotification()
];
