import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store";
import { isDemo } from "../../../api/Service";
import JobTypeDemo from "../../../api/JobTypeDemo.json";
import JobTypeProd from "../../../api/JobTypeProd.json";

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    let url = null;
    url = `api/v1/report/get?userId=${store.getState().persistReducer.userId}`;

    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    if (action.payload.queryType === "oj") {
      yield put({
        type: "JOB_LIST",
        payload: payload.onGoingTasks ?? payload?.response?.onGoingTasks,
      });
      yield put({ type: "CURRENT_PAGE", payload: 1 });
      yield put({ type: "TOTAL_PAGES", payload: 1 });
      yield put({
        type: "TOTAL_ITEMS",
        payload:
          payload?.onGoingTasks?.length ??
          payload?.response?.onGoingTasks?.length,
      });
    } else if (action.payload.queryType === "o") {
      yield put({
        type: "JOB_LIST",
        payload: payload.overDuetasks ?? payload.response.overDuetasks,
      });
      yield put({ type: "CURRENT_PAGE", payload: 1 });
      yield put({ type: "TOTAL_PAGES", payload: 1 });
      yield put({
        type: "TOTAL_ITEMS",
        payload:
          payload?.overDuetasks?.length ??
          payload?.response?.overDuetasks?.length,
      });
    } else if (action.payload.queryType === "jir") {
      yield put({
        type: "JOB_LIST",
        payload: payload?.tasksInReview ?? payload?.response?.tasksInReview,
      });
      yield put({ type: "CURRENT_PAGE", payload: 1 });
      yield put({ type: "TOTAL_PAGES", payload: 1 });
      yield put({
        type: "TOTAL_ITEMS",
        payload:
          payload?.tasksInReview?.length ??
          payload?.response?.tasksInReview?.length,
      });
    } else if (action.payload.queryType === "jtr") {
      yield put({
        type: "JOB_LIST",
        payload: payload.tasksToReview ?? payload?.response?.tasksToReview,
      });
      yield put({ type: "CURRENT_PAGE", payload: 1 });
      yield put({ type: "TOTAL_PAGES", payload: 1 });
      yield put({
        type: "TOTAL_ITEMS",
        payload:
          payload?.tasksToReview?.length ??
          payload?.response?.tasksToReview?.length,
      });
    } else {
      yield put({ type: "JOB_LIST", payload: payload.items });
      yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
      yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
      yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });
    }

    let formatUrl = "api/v1/dropdown/client";
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });

    yield put({ type: "CLIENT_DROPDOWN_LIST", payload: payload });

    if (
      !isNaN(store.getState().persistReducer.clientList?.length) &&
      store.getState().persistReducer.clientList?.length <= 0
    ) {
      formatUrl = "api/v1/dropdown/jobtype";
      yield request("get", action.payload, formatUrl).then((response) => {
        payload = response;
      });
      const companyId = localStorage.getItem("#companyId", payload.companyId);
      if (!companyId) {
        payload = [];
      }
      if (isDemo) {
        const data = JobTypeDemo.find((x) => x.companyId.toString() === companyId.toString());
        const filteredArray = payload?.filter((item) =>
          data.jobType.includes(parseInt(item.value, 10))
        );
        payload = filteredArray;
      } else {
        const data = JobTypeProd.find(
          (x) => x.companyId.toString() === companyId.toString()
        );
        const filteredArray = payload?.filter((item) =>
          data.jobType.includes(parseInt(item.value, 10))
        );
        payload = filteredArray;
      }
      yield put({ type: "JOBTYPE_DROPDOWN_LIST", payload: payload });
    }
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    yield put({ type: "DISPLAY_LOADER", payload: false });

    console.log("report-job-saga", e.response);
    console.log(e);

    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}

export default function* watcherGetCardJobs() {
  yield takeEvery("LOAD_CARD_JOB", workerSaga);
}
