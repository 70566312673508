const initialState = {
  jobs: [],
  clientList: [],
  taskFiles: [],
  tasks: [],
  pendingTask: [],
  jobDetail: {},
  user_tasks: [],
  userComments: [],
  dashboard: {},
  piechart: [],
  pie_overdue: 0,
  csv: [],
  jobTaskList: [],
  jobTypeList: [],
  jobTypeJobList: [],
  pie_today: 0,
  pie_week: 0,
  pie_next_week: 0,
  overdueJobCount: 0,
  overdueJobHours: 0,
  todayJobCount: 0,
  sidebarShow: "responsive",
  todayJobHours: 0,
  weekJobHours: 0,
  weekJobCount: 0,
  todayTask: [],
  add_task_modal: false,
  add_job_modal: false,
  add_staff_modal: false,
  edit_job_modal: false,
  add_client_modal: false,
  edit_client_modal: false,
  nextWeekJobCount: 0,
  nextWeekJobHours: 0,
  pie_over_due: 0,
  todayJob: [],
  summary: {},
  jobs: [],
  tasks: [],
  taskFiles: [],
  taskFilesToDelete: {},
};

function removeDuplicatesById(arr) {
  const uniqueIds = new Set();
  const result = [];

  for (const item of arr) {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      result.push(item);
    }
  }

  return result;
}
function JobReducer(state = initialState, action) {
  if (action.type === "ADD_TASK_FILE") {
    var jobDetails = {
      ...state,
      taskFiles: action.payload,
    };
    return jobDetails;
  }
    if (action.type === "DELETE_TASK_FILE") {
      var jobDetails = {
        ...state,
        taskFilesToDelete: action.payload,
      };
      return jobDetails;
    }

  if (action.type === "JOB_LIST") {
    var jobDetails = {
      ...state,
      jobs: removeDuplicatesById(action?.payload),
    };
    return jobDetails;
  }
  if (action.type === "JOB_DETAILS") {
    var jobDetails = {
      ...state,
      jobDetail: action.payload,
    };
    return jobDetails;
  }

  if (action.type === "TASK_LIST") {
    var jobDetails = {
      ...state,
      tasks: action.payload,
    };
    return jobDetails;
  }
  if (action.type === "USER_COMMENTS") {
    var jobDetails = {
      ...state,
      userComments: action.payload,
    };
    return jobDetails;
  }
  if (action.type === "DASHBOARD") {
    return {
      ...state,
      todayJob: action.payload.todayJob,
      todayTask: action.payload.todayTask,
      pie_today: action?.payload?.pieChart[1],
      pie_week: action?.payload?.pieChart[2],
      pie_next_week: action?.payload?.pieChart[3],
      pie_over_due: action?.payload?.pieChart[0],
      jobSummary: action.payload.jobSummary,
    };
  }
  if (action.type === "SUMMARY") {
    return {
      ...state,
      summary: action.payload,
    };
  }
  if (action.type === "TASK_DONE_TODAY") {
    return {
      ...state,
      tasks: action.payload,
    };
  }
  if (action.type === "TASK_FILE") {
    return {
      ...state,
      taskFiles: action.payload,
    };
  }
  if (action.type === "JOB_DONE_TODAY") {
    return {
      ...state,
      jobs: action.payload,
    };
  }
  if (action.type === "USER_TASK_LIST") {
    var jobDetails = {
      ...state,
      user_tasks: action.payload,
    };
    return jobDetails;
  }

  if (action.type === "PENDING_TASK_TO_DO") {
    var jobDetails = {
      ...state,
      pendingTask: action.payload,
    };
    return jobDetails;
  }

  if (action.type === "DASHBOARD_CSV") {
    var jobDetails = {
      ...state,
      csv: action.payload,
    };
    return jobDetails;
  }

  if (action.type === "JOBTYPE_JOB_DROPDOWN_LIST") {
    var userdetails = {
      ...state,
      jobTypeJobList: action.payload,
    };
    return userdetails;
  }

  if (action.type === "JOB_TASK_DROPDOWN") {
    var userdetails = {
      ...state,
      jobTaskList: action.payload,
    };
    return userdetails;
  }

  return state;
}

export default JobReducer;
