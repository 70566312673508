import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/hashrouter";

export default function* watcherUpdateUserAdminSaga() {
  yield takeEvery("UPDATE_USER_ADMIN", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    yield request("post", action.payload, "api/update-user").then(
      (response) => {
        payload = response;
      }
    );
    toast.success("User Updated Successfully");
    localStorage.setItem("firstNameV", "");
    localStorage.setItem("lastNameV", "");
    localStorage.setItem("userTypeV", "")
    localStorage.setItem("jobTypeV", "")
    localStorage.setItem("positionV", "")
    yield put({ type: "DISPLAY_LOADER", payload: false });
    history.push("/user/users")
  } catch (e) {
    yield put({ type: "SUBMIT_LOADING_BUTTON_SPINNERS", payload: false });
    console.log("UPDATE-user-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("UPDATE-user-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
