import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherGetUsersDropdown() {
  yield takeEvery("LOAD_USER_DROPDOWN", workerSaga);
}
function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let url = `/api/v1/dropdown/user`;
    let payload = {};
    yield request("get", action?.payload, url).then((response) => {
      payload = response;
    });

    yield put({ type: "PERSIST_USERS", payload: payload });
    url = `api/users/`;

    yield request("get", action?.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "USERS_DROPDOWN_LIST", payload: payload });
    yield put({ type: "DISPLAY_LOADER", payload: false });

  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("users-dropdown-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
