import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherDeleteTaskFileSaga() {
  yield takeEvery("DELETE_TASK_FILE", workerSaga);
}

function* workerSaga(action) {
  try {
    console.log("Delete_File_Task Was Called --", action.payload);
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS", payload: true });
    let payload = {};
    yield request("delete", action.payload, "api/v1/task/task-file").then(
      (response) => {
        payload = response;
      }
    );
    setTimeout(() => {
      // Reload the page after 5 seconds
      window.location.reload();
    }, 500);
    alert("File Deleted Successfully");
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS", payload: false });
  } catch (e) {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS", payload: false });
    console.log("add-task-file-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  }
}
