import React, { Component } from "react";
import { HashRouter, BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/resetPassword")
);
const InputCode = React.lazy(() => import("./views/pages/login/inputCode"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const RegisterCompany = React.lazy(() =>
  import("./views/pages/registerCompany/Register")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              path="/registerCompany"
              name="Register Company"
              render={(props) => <RegisterCompany {...props} />}
            />
            <Route
              path="/reset-password"
              name="Reset Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              path="/input-code"
              name="Input Code"
              render={(props) => <InputCode {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.userReducer.roles,
    buttonloader: state.utilityReducer.buttonloader,
    spinner: state.utilityReducer.spinner,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SetLoader() {
    dispatch({ type: "LOADING_BUTTON_SPINNER" });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
