const initialState = {
  userRoleDetails: {},
};

function UserDetailsReducer(state = initialState, action) {
  if (action.type === "PERSIST_USER_DETAILSe") {
    var userdetails = {
      ...state,
      userRoleDetails: action.payload,
    };
    return userdetails;
  }

  return state;
}

export default UserDetailsReducer;
