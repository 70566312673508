import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { isDemo } from "../../../api/Service";
import JobTypeDemo from "../../../api/JobTypeDemo.json";
import JobTypeProd from "../../../api/JobTypeProd.json";

export default function* watcherGetUser() {
  yield takeEvery("LOAD_USER_DETAIL", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    var url = `api/user/${action.payload}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });

    if (action.set !== "Profile") {
      yield put({ type: "PERSIST_EMAIL", payload: payload.email });
      yield put({ type: "PERSIST_FIRST_NAME", payload: payload.firstName });
      yield put({ type: "PERSIST_LAST_NAME", payload: payload.lastName });
      yield put({ type: "PERSIST_PHONE_NUMBER", payload: payload.phoneNumber });
      yield put({ type: "PERSIST_IMAGE_URL", payload: payload.imageUrl });
      yield put({ type: "PERSIST_USER_DETAIL", payload: payload });
      yield put({ type: "USER_DETAIL", payload: payload });
      yield put({ type: "DISPLAY_LOADER", payload: false });
    } else {
      yield put({ type: "USER_PROFILE_RECORD", payload: payload });
      var formatUrl = `api/userType`;
      yield request("get", action.payload, formatUrl).then((response) => {
        payload = response;
      });
      yield put({ type: "USER_TYPE_LIST", payload: payload });
      var formatUrl = `api/position`;
      yield request("get", action.payload, formatUrl).then((response) => {
        payload = response;
      });
      yield put({ type: "POSITION_LIST", payload: payload });
      var formatUrl = `api/v1/dropdown/jobtype`;
      yield request("get", action.payload, formatUrl).then((response) => {
        payload = response;
      });
      const companyId = localStorage.getItem("#companyId", payload.companyId);
      if (!companyId) {
        payload = [];
      }
      if (isDemo) {
        const data = JobTypeDemo.find((x) => x.companyId.toString() === companyId.toString());
        const filteredArray = payload?.filter((item) =>
          data.jobType.includes(parseInt(item.value, 10))
        );
        payload = filteredArray;
      } else {
        const data = JobTypeProd.find(
          (x) => x.companyId.toString() === companyId.toString()
        );
        const filteredArray = payload?.filter((item) =>
          data.jobType.includes(parseInt(item.value, 10))
        );
        payload = filteredArray;
      }
      yield put({ type: "JOBTYPE_DROPDOWN_LIST", payload: payload });
      yield put({ type: "DISPLAY_LOADER", payload: false });
    }
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    yield put({ type: "DISPLAY_LOADER", payload: false });
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
