import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherReAssignTaskSaga() {
  yield takeEvery("REASSIGN_TASK", workerSaga);
}

function* workerSaga(action) {
  try {
    let payload = {};
    yield request("post", action.payload, "api/v1/task/update-assign").then((response) => {
      payload = response;
    });
    alert("Task Reassigned Successfully!!!!!")
    toast.success("Task Added Successfully");
  } catch (e) {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:false });
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  }
}
