import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { toast } from "react-toastify";

export default function* watcherAddNotification() {
  yield takeEvery("ADD_NOTIFICATION", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:true });
    let payload = {};
    yield request("post", {}, "api/v1/report/post-notification").then((response) => {
      payload = response;
    });
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:false });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  }
}
