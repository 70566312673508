import { combineReducers } from "redux";
import UserReducer from "../user-reducer/index";
import UtilityReducer from "../utility-reducer/index";
import ClientReducer from "../client-reducer";
import JobReducer from "../job-reducer";
import SpinnerReducer from "../spinner-reducer/index";
import ReportReducer from "../report-reducer";
import PersistReducer from "../persist-reducer/index";
import UserDetailsReducer from "../user-details-reducer/index";
const rootReducer = combineReducers({
  userReducer: UserReducer,
  utilityReducer: UtilityReducer,
  clientReducer: ClientReducer,
  jobReducer: JobReducer,
  spinnerReducer: SpinnerReducer,
  persistReducer: PersistReducer,
  reportReducer: ReportReducer,
  userdetailsReducer: UserDetailsReducer,
});

export default rootReducer;
