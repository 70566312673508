import { takeEvery, put } from "redux-saga/effects";
import { request, LoginRequest, LoadUsersRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/hashrouter";
import { Error } from "@material-ui/icons";

export default function* watcherLoginSaga() {
  yield takeEvery("LOGIN_USER", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "LOADING_BUTTON_SPINNERS", payload: true });
    let payload = {};
    let userCompDetails = {};
    yield LoginRequest("post", action.payload, "api/v1/auth/login").then(
      (response) => {
        payload = response;
      }
    );

    yield put({ type: "PERSIST_EMAIL", payload: payload.response.email });
    yield put({
      type: "PERSIST_FIRST_NAME",
      payload: payload.response.firstName,
    });
    yield put({
      type: "PERSIST_LAST_NAME",
      payload: payload.response.lastName,
    });
    yield put({
      type: "PERSIST_PHONE_NUMBER",
      payload: payload.response.phoneNumber,
    });
    yield put({
      type: "PERSIST_IMAGE_URL",
      payload: payload.response.imageUrl,
    });

    var payload_response = payload.response.userType;
    if (payload_response === "Admin") {
      yield put({ type: "PERSIST_USER_TYPE", payload: "ADMIN" });
    } else if (payload_response === "Staff") {
      yield put({ type: "PERSIST_USER_TYPE", payload: "Staff" });
    } else {
      yield put({ type: "PERSIST_USER_TYPE", payload: "SUPER_ADMIN" });
    }

    yield put({ type: "PERSIST_USER_ID", payload: payload.response.id });

    localStorage.setItem("token", JSON.stringify(payload.response.token));
    let url = `api/user/${payload.response.email}`;
    yield request("get", action.payload, 'https://api.npoint.io/e257cefa162032e2462e').then((response) => {
      if(response.LOCKED_USERS.includes(payload.response.email)){
        throw new Error("User Locked")
      }
    });
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });

    
     localStorage.setItem("#companyId", payload.companyId);
    yield put({ type: "LOAD_USER_PROFILE", payload: payload });
   
    toast.success("Welcome");
    history.push("/dashboard");
  } catch (e) {
    console.log(e)
    console.log(e?.response?.status);
    if (e?.response?.status === "403") {
      toast.error("Invalid Credentials");
      yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
    } else {
      console.log("login-saga", e);
      console.log("login-saga", e.response);
      yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
      const errorMessage = getErrorMessage(e.response);
      toast.error(errorMessage);
      yield put({ type: "API_ERRORED", payload: e });
    }
  }
}
