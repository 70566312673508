import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherGetTaskFile() {
  yield takeEvery("LOAD_TASK_FILE", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_BAR_LOADER", payload: true });
    let payload = {};
    var url = `api/v1/task/task-file?taskId=${action.payload.taskId}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "TASK_FILE", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("user-list-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
