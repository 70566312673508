const initialState = {
  buttonloader: false,
  spinner: false,
  currentPage: 1,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0,
  dashboard: {},
  piechart: [],
  pie_overdue: 0,
  pie_today: 0,
  pie_week: 0,
  pie_next_week: 0,
  barloader:false,
  overdueJobCount: 0,
  overdueJobHours: 0,
  todayJobCount: 0,
  sidebarShow: 'responsive',
  onGoing: 0,
  overDue: 0,
  taskInReview: 0,
  taskToReview: 0,
  todayJobHours: 0,
  weekJobHours: 0,
  weekJobCount: 0,
  todayTask: [],
  add_task_modal: false,
  add_job_modal: false,
  add_staff_modal: false,
  edit_job_modal: false,
  add_client_modal: false,
  edit_client_modal: false,
  nextWeekJobCount: 0,
  nextWeekJobHours: 0,
  pie_over_due: 0,
  todayJob: [],
  summary: {},
  jobs: [],
  tasks: [],
};

function UtilityReducer(state = initialState, action) {
  if (action.type === "DASHBOARD") {
    return {
      ...state,
      todayJob: action.payload.todayJob,
      onGoing: action.payload.ongoingJob,
      taskToReview: action.payload.taskToReview,
      taskInReview: action.payload.taskInReview,
      overDue: action.payload.overDueJob,
      todayTask: action.payload.todayTask,
      pie_today: action?.payload?.pieChart[1],
      pie_week: action?.payload?.pieChart[2],
      pie_next_week: action?.payload?.pieChart[3],
      pie_over_due: action?.payload?.pieChart[0],
      jobSummary: action.payload.jobSummary,
    };
  }

  if (action.type === "TASK_DONE_TODAY") {
    return {
      ...state,
      tasks: action.payload,
    };
  }

  if (action.type === "JOB_DONE_TODAY") {
    return {
      ...state,
      jobs: action.payload,
    };
  }

 
 

  if (action.type === "DISPLAY_LOADER") {
    return {
      ...state,
      spinner: action.payload,
    };
  }
  if (action.type === "DISPLAY_BAR_LOADER") {
    return {
      ...state,
      barloader: action.payload,
    };
  }


  if (action.type === "DISPLAY_ADD_TASK_MODAL") {
    return {
      ...state,
      add_task_modal: action.payload,
    };
  }
  if (action.type === "DISPLAY_ADD_JOB_MODAL") {
    return {
      ...state,
      add_job_modal: !state.add_job_modal,
    };
  }
  if (action.type === "DISPLAY_EDIT_JOB_MODAL") {
    return {
      ...state,
      edit_job_modal: !state.edit_job_modal,
    };
  }
  if (action.type === "DISPLAY_ADD_CLIENT_MODAL") {
    return {
      ...state,
      add_client_modal: !state.add_client_modal,
    };
  }
  if (action.type === "DISPLAY_EDIT_CLIENT_MODAL") {
    return {
      ...state,
      edit_client_modal: !state.edit_client_modal,
    };
  }
  if (action.type === "DISPLAY_ADD_STAFF_MODAL") {
    return {
      ...state,
      add_staff_modal: !state.add_staff_modal,
    };
  }

  if (action.type === "CURRENT_PAGE") {
    return {
      ...state,
      currentPage: action.payload,
    };
  }

  if (action.type === "ITEMS_PER_PAGE") {
    return {
      ...state,
      itemsPerPage: action.payload,
    };
  }

  if (action.type === "TOTAL_ITEMS") {
    return {
      ...state,
      totalItems: action.payload,
    };
  }

  if (action.type === "TOTAL_PAGES") {
    return {
      ...state,
      totalPages: action.payload,
    };
  }
  return state;
}

export default UtilityReducer;
