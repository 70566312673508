import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherUpdateTaskSaga() {
  yield takeEvery("UPDATE_TASK", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER",payload:true });
    let payload = {};
    yield request("post", action.payload, "api/v1/task/update").then((response) => {
      payload = response;
    });
    alert("Task Assigned Successfully!!!!!")
    toast.success("Task Added Successfully");
    yield put({ type: "JOBTYPE_JOB_DROPDOWN_LIST", payload: [] });
    yield put({ type: "DISPLAY_LOADER",payload:false });
  } catch (e) {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:false });
    console.log("update-task-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  }
}
