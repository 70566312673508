import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/hashrouter";
import { store } from "../../store";
export default function* watcherUpdateUserSaga() {
  yield takeEvery("UPDATE_USER", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    yield request("post", action.payload, "api/update-user").then(
      (response) => {
        payload = response;
      }
    );
    toast.success("User Updated Successfully");

    var url = `api/user/${store.getState().persistReducer.email}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "PERSIST_EMAIL", payload: payload.email });
    yield put({ type: "PERSIST_FIRST_NAME", payload: payload.firstName });
    yield put({ type: "PERSIST_LAST_NAME", payload: payload.lastName });
    yield put({ type: "PERSIST_PHONE_NUMBER", payload: payload.phoneNumber });
    yield put({ type: "PERSIST_IMAGE_URL", payload: payload.imageUrl });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("UPDATE-user-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("UPDATE-user-saga", e.response);
    if (e.response.data.code == "400 BAD_REQUEST") {
      alert("Incorrect old password!!!")
    } else {
      console.log(e.response);
      console.log(e.response);
      const errorMessage = getErrorMessage(e.response);
      toast.error(errorMessage);
      yield put({ type: "API_ERRORED", payload: e });
    }

  }
}
