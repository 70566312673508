import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";


export default function* watchherGetUserComment() {
    yield takeEvery("LOAD_USER_COMMENTS", workerSaga);
}

function* workerSaga(action) {
    try {
        yield put({ type: "DISPLAY_LOADER", payload: true });
        let payload = {};
        var url = `api/v1/task/comments?taskId=${action.payload}`;
        yield request("get", action.payload, url).then((response) => {
            payload = response;
        });
        yield put({ type: "USER_COMMENTS", payload: payload.response });
        yield put({ type: "DISPLAY_LOADER", payload: false });
    } catch (e) {
        yield put({ type: "LOADING_BUTTON_SPINNER" });
        console.log("user-list-saga", e);
        yield put({ type: "DISPLAY_LOADER", payload: false });
        console.log("user-task-list-saga", e.response);
        console.log(e.response);
        const errorMessage = getErrorMessage(e.response);
        toast.error(errorMessage);
        yield put({ type: "API_ERRORED", payload: e });
    }
}
