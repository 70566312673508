import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

// Define action types as constants
const LOAD_REPORT = "LOAD_REPORT";
const DISPLAY_LOADER = "DISPLAY_LOADER";
const REPORT_LIST = "REPORT_LIST";
const PERSIST_USERS = "PERSIST_USERS";
const LOADING_BUTTON_SPINNER = "LOADING_BUTTON_SPINNER";
const API_ERRORED = "API_ERRORED";

export default function* watcherGetReportSaga() {
  yield takeEvery(LOAD_REPORT, workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: DISPLAY_LOADER, payload: true });

    // Use async/await for making requests
    let payload = {};

    const reportUrl = `api/v1/report/get?userId=${action.payload.userId}`;
    const reportResponse = yield request("get", action.payload, reportUrl);
    yield put({ type: REPORT_LIST, ...reportResponse });

    //const usersUrl = `/api/v1/dropdown/user`;
   
   // const usersResponse = yield request("get", action.payload, usersUrl);
    //yield put({ type: PERSIST_USERS, payload: usersResponse });

    yield put({ type: DISPLAY_LOADER, payload: false });
  } catch (e) {
    yield put({ type: LOADING_BUTTON_SPINNER });
    console.error("user-list-saga", e);

    yield put({ type: DISPLAY_LOADER, payload: false });

    console.error("user-list-saga", e.response);
    console.error(e.response);

    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);

    yield put({ type: API_ERRORED, payload: e });
  }
}
