import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import history from "../../../router/browserrouter";

export default function* watcherDeleteTaskSaga() {
  yield takeEvery("DELETE_TASK", workerSaga);
}
function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    var url = `api/v1/task/delete?taskId=${action.payload.taskId}`;
    yield request("post", action.payload, url).then((response) => {
      payload = response;
    });
    var url = `api/v1/task?jobId=${action.payload.jobId}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "TASK_LIST", payload: payload.response.taskList });
    yield put({ type: "JOB_DETAILS", payload: payload.response.job });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("user-list-saga", e);
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
