import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherGetJobTypeJob() {
  yield takeEvery("LOAD_JOB_TYPE_JOB", workerSaga);
}

function* workerSaga(action) {
  try {
    var payload = {};
    yield put({ type: "DISPLAY_BAR_LOADER", payload: true });
    var formatUrl = `api/v1/job/getjob?from=${action.payload.startDate}&jobTypeId=${action.payload.jobTypeId}&to=${action.payload.endDate}`;
    yield request("get", {}, formatUrl).then((response) => {
      payload = response;
    });
    var jobResponses = [];
    if (payload.responseDescription === "SUCCESSFUL") {
      var i = 0;
      for (i; i < payload.response.length; i++) {
        jobResponses.push({
          label: payload.response[i].jobName,
          value: payload.response[i].id,
          dateIn:payload.response[i].dateIn,
          dateOut: payload.response[i].dateOut
        });
      }
    }
    yield put({ type: "JOBTYPE_JOB_DROPDOWN_LIST", payload: jobResponses });
    yield put({
        type: "JOB_TASK_DROPDOWN",
        payload: [],
      });
    yield put({ type: "DISPLAY_BAR_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "DISPLAY_BAR_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
