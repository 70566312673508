const initialState = {
    clients: []
};


function ClientReducer(state = initialState, action) {


    if (action.type === "CLIENT_LIST") {
        var clientDetails = {
            ...state,
            clients: action.payload
        };
        return clientDetails;
    }

    return state;
}

export default ClientReducer;














