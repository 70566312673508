const initialState = {
  triggerLogging: false,
  users: null,
  user: null,
  userType: null,
  isRegisterSuccessful: false,
  userEmail:null,
  firstName: null,
  lastName: null,
  userProfile: null,
  phoneNumber: null,
  email: null,
  imageUrl: "",
  position: null,
  jobType:null,
  roles: [],
  users1:[],
  userDetail: {},
  industries: null,
  usersList: [],
  userTypeList: [],
  companyId: null,
  positions: [],
  access: null,
  cacNumber: null,
};

function UserReducer(state = initialState, action) {
  if (action.type === "USERS_DETAILS") {
    var userdetails = {
      ...state,
      user: action.payload,
    };
    return userdetails;
  }
  if (action.type === "LOAD_USER_PROFILE") {
    var userProfile = {
      ...state,
      userProfile: action.payload,
    };
    return userProfile;
  }
  if (action.type === "USER_DETAIL") {
    var userDetail = {
      ...state,
      positionId:action.payload.positionId,
      jobType:action.payload.jobType,
      userTypeId:action.payload.userTypeId,
      firstName: action.payload?.firstName,
      lastName: action.payload?.lastName,
      email: action.payload?.email,
      phoneNumber: action.payload.phoneNumber,
      imageUrl: action.payload?.imageUrl,
    };
    return userDetail;
  }

  if (action.type === "USER_TYPE_LIST") {
   
    var updated_array=[];
    for(var m=0;m<action.payload.length;m++){
      // eslint-disable-next-line eqeqeq
      if(action.payload[m].label!='jjj')
      {
        updated_array.push(action.payload[m])
      }
    }
  
    var userdetails = {
      ...state,
      userTypeList: updated_array,
    };
    return userdetails;
  }

  if (action.type === "USERS_DROPDOWN_LIST") {
    var userdetails = {
      ...state,
      usersList: action.payload,
    };
    return userdetails;
  }
  if (action.type === "USER_PASSWORD_EMAIL") {
    var userdetails = {
      ...state,
      userEmail: action.payload,
    };
    return userdetails;
  }

  if (action.type === "POSITION_LIST") {
    var userdetails = {
      ...state,
      positions: action.payload,
    };
    return userdetails;
  }

  if (action.type === "USER_LIST1") {
    var userdetails = {
      ...state,
      users1: action.payload,
    };
    return userdetails;
  }

  if (action.type === "SET_USER_TYPE") {
    var userdetails = {
      ...state,
      userType: action.payload,
    };
    return userdetails;
  }

  if (action.type === "TRIGGER_LOGIN_SUCCESS") {
    return {
      ...state,
      triggerLogging: !state.payload,
    };
  }

  if (action.type === "TRIGGER_REGISTER_SUCCESS") {
    return {
      ...state,
      isRegisterSuccessful: !state.payload,
    };
  }

  return state;
}

export default UserReducer;
