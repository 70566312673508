const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    phonenumber: "",
    staffDetails: {},
    imageUrl: "",
    users1:[],
    clientList: [],
    jobTypeList: [],
    userRoledetails: {},
    userType: "",
    userId: 0,
    users: []

};


function PersistReducer(state = initialState, action) {



    if (action.type === "PERSIST_EMAIL") {
        var reportDetails = {
            ...state,
            email: action.payload
        };
        return reportDetails;
    }
    if (action.type === "PERSIST_FIRST_NAME") {
        var reportDetails = {
            ...state,
            firstName: action.payload
        };
        return reportDetails;
    }

    if (action.type === "USER_LIST1") {
        var userdetails = {
            ...state,
            users1: action.payload,
        };
        return userdetails;
    }

    if (action.type === "PERSIST_LAST_NAME") {
        var reportDetails = {
            ...state,
            lastName: action.payload
        };
        return reportDetails;
    }

    if (action.type === "PERSIST_PHONE_NUMBER") {
        var reportDetails = {
            ...state,
            phoneNumber: action.payload
        };
        return reportDetails;
    }

    if (action.type === "PERSIST_IMAGE_URL") {
        var reportDetails = {
            ...state,
            imageUrl: action.payload
        };
        return reportDetails;
    }

    if (action.type === "PERSIST_USER_TYPE") {
        var reportDetails = {
            ...state,
            userType: action.payload
        };
        return reportDetails;
    }

    if (action.type === "CLIENT_DROPDOWN_LIST") {
        var reportDetails = {
            ...state,
            clientList: action.payload
        };
        return reportDetails;
    }

    if (action.type === "JOBTYPE_DROPDOWN_LIST") {
        var userdetails = {
            ...state,
            jobTypeList: action.payload,
        };
        return userdetails;
    }
    if (action.type === "PERSIST_USER_DETAILS") {
        var userRoledetails = {
          ...state,
          userRoleDetails: action.payload,
        };
        return userRoledetails;
      }

    if (action.type === "PERSIST_USER_ID") {
        var reportDetails = {
            ...state,
            userId: action.payload
        };
        return reportDetails;
    }

    if (action.type === "PERSIST_STAFF_DETAILS") {
        var reportDetails = {
            ...state,
            staffDetails: action.payload
        };
        return reportDetails;
    }


    if (action.type === "PERSIST_USERS") {
        var reportDetails = {
            ...state,
            users: action.payload
        };
        return reportDetails;
    }

    return state;
}

export default PersistReducer;
