const initialState = {
  reworkCount: 0,
  completedJobCount: 0,
  completedTasks: 0,
  completedOverDueTasks: 0,
  overDueTasks: 0,
  onGoingTasks: 0,
  tasksInReview: 0,
  tasksToReview: 0,
  overdueJobCount: 0,
  completedTaskCount: 0,
  overdueTaskCount: 0,
  report_detail: [],
  report_detail_excel: [],
  user_detail: {}
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case "REPORT_LIST":
      return {
        ...state,
        onGoingTasks: action?.onGoingTasks?.length,
        completedJobCount: action?.completedTasks?.length,
        overdueTaskCount: action?.overDuetasks?.length,
        tasksInReview: action?.tasksInReview?.length,
        tasksToReview: action?.tasksToReview?.length
      };

    case "USER_PROFILE_RECORD":
      return {
        ...state,
        user_detail: action.payload,
      };

    case "REPORT_DETAIL_LIST_FOR_EXCEL":
      return {
        ...state,
        report_detail_excel: action.payload,
      };

    case "REPORT_DETAIL_LIST":
      return {
        ...state,
        report_detail: action.payload,
      };

    default:
      return state;
  }
};

export default ReportReducer;
