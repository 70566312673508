import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store"

export default function* watcherGetDashboardCsvSaga() {
  yield takeEvery("LOAD_DASHBOARD_CSV", workerSaga);
}

function* workerSaga(action) {
  try {
    let payload = [];
    yield request("get", action.payload, `api/v1/job/csv?type=${action.payload}`).then((response) => {
      payload = response;
    });
    yield put({ type: "DASHBOARD_CSV", payload: payload });
  } catch (e) {
    console.log(e)
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("dashboard-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  }
}