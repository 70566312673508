import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";

export default function* watcherAddTaskSaga() {
  yield takeEvery("ADD_TASK", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:true });
    let payload = {};
    yield request("post", action.payload, "api/v1/task/add").then((response) => {
      payload = response;
    });
    var url = `api/v1/job/get?pageNumber=${1}&pageSize=10&clientId=${0}&statusId=${0}&text=${""}&jobTypeId=${0}`;
   
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "JOB_LIST", payload: payload.items });
    yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
    yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
    yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });
    action.setProgress(0);
    action.setProgressPercentage(`${0}%`);
    yield put({ type: "DISPLAY_ADD_TASK_MODAL" });
    toast.success("Task Added Successfully");
    action.setLarge(false)
    action.setUploading(false)
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:false });
  } catch (e) {
    yield put({ type: "SUBMIT_JOB_LOADING_BUTTON_SPINNERS",payload:false });
    console.log("add-user-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  }
}
