import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store"
export default function* watcherGetReportDetailForExcelSaga() {
  yield takeEvery("LOAD_REPORT_DETAIL_FOR_EXCEL", workerSaga);
}
function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true })
    let payload = {};
    var url = `api/v1/report/excel?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "REPORT_DETAIL_LIST_FOR_EXCEL", payload: payload.response });
  
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("user-list-saga", e);
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
