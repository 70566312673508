import { takeEvery, put, call } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store";

export default function* watcherValidateSaga() {
  yield takeEvery("VALIDATE", workerSaga);
}

function getTotalPages(totalItems) {
  return Math.ceil(totalItems / 10);
}
function getNextPageNumber(currentPage, totalItems, itemsPerPage) {
  const totalPages = getTotalPages(totalItems, itemsPerPage);
  const lastPageItemCount = totalItems % itemsPerPage || itemsPerPage;
  
  if (currentPage > totalPages) {
    return totalPages;
  } else if (currentPage === totalPages && lastPageItemCount === 0) {
    return totalPages;
  } else {
    return currentPage;
  }
}
function* workerSaga(action) {
  try {
    let payload = {};
   
    if (action.types === "Admin") {

      
      yield call(
        request,
        "post",
        action.payload,
        `api/v1/task/validate?taskId=${action.payload.taskId}&userId=${store.getState().persistReducer.userId}`
      );

       const response = window.reviewer;
       response.items =     response?.items?.filter(x=>x.taskId?.toString() !== action?.payload?.taskId?.toString())
       response.totalPages=  getTotalPages(response.totalItems);
       response.totalItems = response?.items?.length;
       response.currentPage = getNextPageNumber(response.currentPage,response.totalItems, 10 )
        console.log(response.currentPage)
       window.reviewer = response;
      yield put({ type: 'CURRENT_PAGE', payload: response.currentPage });
      yield put({ type: 'TOTAL_PAGES', payload: response.totalPages });
      yield put({ type: 'TOTAL_ITEMS', payload: response.totalItems });
      yield put({ type: "PENDING_TASK_TO_DO", payload: response.items });
      yield put({ type: "DISPLAY_LOADER", payload: false });
    } else {
     
       yield call(request, "post", action.payload, `api/v1/task/finished`);
    
      const response = window?.user?.filter(x=>x.id?.toString() !== action?.payload?.taskId?.toString())
      window.user = response;
      yield put({ type: "USER_TASK_LIST", payload: response });
      yield put({ type: "DISPLAY_LOADER", payload: false });
    }
  } catch (e) {
    console.log(e)
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("pending-task-user-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  }
}


