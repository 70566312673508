const initialState = {
   
    buttonloaders:false,
    _submitbuttonloaders:false,
    _submitJobbuttonloaders:false
    
  };
  
  function SpinnerReducer(state = initialState, action) {
    if (action.type === "LOADING_BUTTON_SPINNERS") {
        return {
          ...state,
          buttonloaders: action.payload,
        };
      }
      if (action.type === "SUBMIT_LOADING_BUTTON_SPINNERS") {
        return {
          ...state,
          _submitbuttonloaders: action.payload,
        };
      }
      if (action.type === "SUBMIT_JOB_LOADING_BUTTON_SPINNERS") {
        return {
          ...state,
          _submitJobbuttonloaders: action.payload,
        };
      }

      
      return state;
  }
  
  export default SpinnerReducer;
  