const customErrorMessage =
  "Request failed with an error. Refresh your browser or contact support";
const connectionErrorMessage =
  "Connection error. Please ensure you have an active internet connection";

export const getErrorMessage = (payload) => {
  if (payload === undefined) {
    return "Network Error";
  }
  if (payload.status === 400 && payload.data.description != undefined) {
    const message = payload.data.description;
    return message;
  }
  if (payload.status === 400 && payload.data.responseDescription != undefined) {
    const message = payload.data.responseDescription;
    return message;
  }
  if (payload.status === 401 ) {
    return "User Locked or Bad Credentials";
  }
  if (payload.status === 401 && payload.data.description != undefined) {
    const message = payload.data.description;
    return message;
  }
  if (payload.status === 401 && payload.data.responseDescription != undefined) {
    const message = payload.data.responseDescription;
    return message;
  }

  if (payload.data.status === 500 && payload.data.message) {
    return payload.data.message;
  }
  if (payload.status === 500) {
    return "An Unexpected error occured, please try again later!!";
  }
  if (payload.response === undefined && payload.message) {
    return payload.message === "Network Error"
      ? connectionErrorMessage
      : payload.message;
  }
  return customErrorMessage;
};
