import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { request, LoadUsersRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store";
import JobTypeDemo from "../../../api/JobTypeDemo.json";
import { isDemo } from "../../../api/Service";
import JobTypeProd from "../../../api/JobTypeProd.json";

function* fetchJobList(action) {
  yield request("get", action.payload, 'https://api.npoint.io/e257cefa162032e2462e').then((response) => {
    if(response.LOCKED_USERS.includes(store.getState().persistReducer.email)){
      toast.error('User Locked')
      throw new Error("User Locked")
    }
  });
  let payload = {};
  let url = null;
  if (action.types === "Super Admin") {
    url = `api/v1/job/get?pageNumber=${action.payload.pageNumber}&pageSize=10&clientId=${action.payload.clientId}&statusId=${action.payload.statusId}&text=${action.payload.text}&jobTypeId=${action.payload.jobTypeId}`;
  } else if (action.types === "Staff") {
    url = `api/job?pageNumber=${action.payload.pageNumber}&pageSize=10&userId=${action.userId}`;
  } else if (action.types === "Admin") {
    url = `api/reviewer?pageNumber=${action.payload.pageNumber}&pageSize=10&reviewerId=${action.userId}`;
  }

  const response = yield call(request, "get", action.payload, url);
  payload = response;

  yield put({ type: "JOB_LIST", payload: payload.items });
  yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
  yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
  yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });

  return payload;
}

function* fetchJobTypeDropdownList(action, companyId) {
  let formatUrl = "api/v1/dropdown/jobtype";
  const response = yield call(request, "get", action.payload, formatUrl);
  const jobTypeDropdownList = response;
  let filteredArray = [];
  if (isDemo) {
    const data = JobTypeDemo.find(
      (x) => x.companyId.toString() === companyId.toString()
    );
    filteredArray = jobTypeDropdownList?.filter((item) =>
      data.jobType.includes(parseInt(item.value, 10))
    );
  } else {
    const data = JobTypeProd.find(
      (x) => x.companyId.toString() === companyId.toString()
    );

    filteredArray = jobTypeDropdownList?.filter((item) =>
      data.jobType.includes(parseInt(item.value, 10))
    );
  }
  yield put({ type: "JOBTYPE_DROPDOWN_LIST", payload: filteredArray });

  const userCompDetails = yield call(
    LoadUsersRequest,
    companyId,
    store.getState().persistReducer.email
  );
  yield put({ type: "PERSIST_USER_DETAILS", payload: userCompDetails ?? {} });
}

function* fetchFilteredJobList(payload) {
  yield put({ type: "JOB_LIST", payload: payload.items });
  yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
  yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
  yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });
}

function* fetchClientDropdownList(action) {
  const formatUrl = "api/v1/dropdown/client";
  const response = yield call(request, "get", action.payload, formatUrl);
  const clientDropdownList = response;

  yield put({ type: "CLIENT_DROPDOWN_LIST", payload: clientDropdownList });

  return clientDropdownList;
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });

    const companyId = localStorage.getItem("#companyId");

    
    const [jobListPayload] = yield all([
      call(fetchJobList, action),
      call(fetchJobTypeDropdownList, action, companyId),
      call(fetchClientDropdownList, action)
    ]);
    const [] = yield all([
      
      call(fetchFilteredJobList, jobListPayload),
      
    ]);

    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    console.log(e);
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    yield put({ type: "DISPLAY_LOADER", payload: false });

    console.log("job-list-saga", e.response);
    console.log(e.response);

    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}

export default function* watcherGetJobs() {
  yield takeEvery("LOAD_JOB", workerSaga);
}
