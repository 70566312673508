import { takeEvery, put } from "redux-saga/effects";
import { request, LoadUsersRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store }from '../../store';


export default function* watcherGetJobHistory() {
  yield takeEvery("LOAD_JOB_HISTORY", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    const companyId = localStorage.getItem("#companyId", payload.companyId);
    let userCompDetails = {};
    yield LoadUsersRequest(companyId, store.getState().persistReducer.email).then(
      (response) => {
        userCompDetails = response;
      }
    );
    yield put({ type: "PERSIST_USER_DETAILS", payload: userCompDetails ?? {} });

  
    var url = `api/v1/task?jobId=${action.payload}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "TASK_LIST", payload: payload.response.taskList });
    yield put({ type: "JOB_DETAILS", payload: payload.response.job });
     yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("job-history-saga", e);
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
    console.log("job-history-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
