import { takeEvery, put } from "redux-saga/effects";
import { request } from '../../../api/Service';
import { getErrorMessage } from '../../../utils/errorHandler';
import { toast } from 'react-toastify';


export default function* watcherGetClients() {
    yield takeEvery("LOAD_CLIENT", workerSaga);
}

function* workerSaga(action) {
    try {
        yield put({ type: "DISPLAY_LOADER" ,payload:true }); 
        let payload = {};
        var url = `api/clients/${action.payload}`;
        yield request("get", action.payload, url).then(response => {
            payload = response;
        });
        yield put({ type: "CLIENT_LIST", payload: payload.items })
        yield put({ type: "CURRENT_PAGE", payload: payload.currentPage })
        yield put({ type: "TOTAL_PAGES", payload: payload.totalPages })
        yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems })
        yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
    } catch (e) {
        yield put({ type: "LOADING_BUTTON_SPINNER" });
        yield put({ type: "DISPLAY_LOADER" });
        console.log("clients-saga", e.response)
        console.log(e.response)
        const errorMessage = getErrorMessage(e.response);
        toast.error(errorMessage);
        yield put({ type: "API_ERRORED", payload: e });
    }
}
