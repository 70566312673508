import { takeEvery, put } from "redux-saga/effects";
import { request, LoadUsersRequest } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { isDemo } from "../../../api/Service";
import JobTypeDemo from "../../../api/JobTypeDemo.json";
import JobTypeProd from "../../../api/JobTypeProd.json";
import { store }from '../../store';
export default function* watcherGetUsers() {
  yield takeEvery("LOAD_USER", workerSaga);
}
function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER", payload: true });
    let payload = {};
    var url = `api/users/${1}`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
    yield put({ type: "USER_LISTS", payload: payload.items });
    yield put({ type: "USER_LIST1", payload: payload.items });
    yield put({ type: "CURRENT_PAGE", payload: payload.currentPage });
    yield put({ type: "TOTAL_PAGES", payload: payload.totalPages });
    yield put({ type: "TOTAL_ITEMS", payload: payload.totalItems });
    let formatUrl = "api/v1/dropdown/jobtype";
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    const companyId = localStorage.getItem("#companyId", payload.companyId);
    if (!companyId) {
      payload = [];
    }
    let userCompDetails = {};
    yield LoadUsersRequest(companyId, store.getState().persistReducer.email).then(
      (response) => {
        userCompDetails = response;
      }
    );

    yield put({ type: "PERSIST_USER_DETAILS", payload: userCompDetails ?? {} });
    
    if (isDemo) {
      const data = JobTypeDemo.find((x) => x.companyId.toString() === companyId.toString());
      const filteredArray = payload?.filter((item) =>
        data.jobType.includes(parseInt(item.value, 10))
      );
      payload = filteredArray;
    } else {
      const data = JobTypeProd.find(
        (x) => x.companyId.toString() === companyId.toString()
      );
      const filteredArray = payload?.filter((item) =>
        data.jobType.includes(parseInt(item.value, 10))
      );
      payload = filteredArray;
    }
    yield put({ type: "JOBTYPE_DROPDOWN_LIST", payload: payload });
    formatUrl = `api/userType`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "USER_TYPE_LIST", payload: payload });
    formatUrl = `api/position`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });

    yield put({ type: "POSITION_LIST", payload: payload });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    yield put({ type: "DISPLAY_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
