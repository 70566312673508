import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { isDemo } from "../../../api/Service";
import JobTypeDemo from "../../../api/JobTypeDemo.json";
import JobTypeProd from "../../../api/JobTypeProd.json";

export default function* watcherGetJobType() {
  yield takeEvery("LOAD_JOB_TYPE", workerSaga);
}

function* workerSaga(action) {
  try {
    var payload = {};
    yield put({ type: "DISPLAY_LOADER", payload: true });
    var formatUrl = `api/v1/dropdown/jobtype`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    const companyId = localStorage.getItem("#companyId", payload.companyId);
    if (!companyId) {
      payload = [];
    }
    if (isDemo) {
      const data = JobTypeDemo.find((x) => x.companyId.toString() === companyId.toString());
      const filteredArray = payload?.filter((item) =>
        data.jobType.includes(parseInt(item.value, 10))
      );
      payload = filteredArray;
    } else {
      const data = JobTypeProd.find(
        (x) => x.companyId.toString() === companyId.toString()
      );
      const filteredArray = payload?.filter((item) =>
        data.jobType.includes(parseInt(item.value, 10))
      );
      payload = filteredArray;
    }
    yield put({ type: "JOBTYPE_DROPDOWN_LIST", payload: payload });
    formatUrl = `api/userType`;
    yield request("get", action.payload, formatUrl).then((response) => {
      payload = response;
    });
    yield put({ type: "USER_TYPE_LIST", payload: payload });
 
    var url = `api/users/`;
    yield request("get", action.payload, url).then((response) => {
      payload = response;
    });
  
    yield put({ type: "USERS_DROPDOWN_LIST", payload: payload });
   
    yield put({ type: "DISPLAY_LOADER", payload: false });
  } catch (e) {
    yield put({ type: "DISPLAY_BAR_LOADER", payload: false });
    console.log("user-list-saga", e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.źesponse);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
  }
}
